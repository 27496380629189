/* --- Global --- */
import {Helmet} from 'react-helmet';
import wave from '@assets/images/bump.png';
/* --- Local --- */
import {QuestCard} from '@components';
import {FormCompleteQuest} from '@forms';
import questListConsenQuest from '@data/questListConsenQuest';
import questListMillionDevs from '@data/questListMillionDevs';

/* --- Component --- */
const PageHome = props => {
  return (
    <>
      <SearchEngineOptimization />
      <Showcase />
      <Content />
    </>
  );
};

const Showcase = props => {
  return (
    <Atom.Flex
      center
      column
      sx={{
        color: 'white',
        // minHeight: [0, 0, '50vh'],
        pt: 6,
        pb: 6,
        zIndex: 1000,
      }}>
      <Atom.BackgroundGradient gradient="bluePurple" direction={0} />
      <Atom.Absolute sx={{bottom: 0, left: 0, right: 0}}>
        <Atom.Image src={wave} sx={{height: 80, width: '100%'}} />
      </Atom.Absolute>
      <Atom.Container
        sx={{
          pt: 2,
          pb: 4,
          maxWidth: ['100%', '100%', 620, 780],
        }}>
        <Atom.Box sx={{flex: 2, textAlign: 'center'}}>
          <Atom.Heading
            giga
            sx={{
              fontSize: [5, 5, 5, 6],
            }}>
            <Atom.Span sx={{fontWeight: 300}}>How It Works</Atom.Span>
            <br />
            <Atom.Span
              sx={{
                fontSize: [5, 5, 5],
              }}>
              A magical adventure powered by the blockchain.
            </Atom.Span>
          </Atom.Heading>
        </Atom.Box>
      </Atom.Container>
    </Atom.Flex>
  );
};

const Content = props => {
  return (
    <Atom.Box>
      <Atom.Container
        sx={{
          pt: 2,
          pb: 4,
          maxWidth: ['100%', '100%', 620, 780],
        }}>
        <Atom.Flex center column>
          <Atom.Heading as="h5" giga>
            The Basics
          </Atom.Heading>
          <Atom.Heading as="h5" md normal sx={{color: 'blue'}}>
            Questing is magical. <strong>And so are you!</strong>
          </Atom.Heading>
        </Atom.Flex>
        <Atom.HorizontalRule
          sx={{
            mt: 4,
            mb: 4,
            width: 50,
            mx: 'auto',
          }}
        />
        <Atom.Paragraph>
          Aenean scelerisque neque augue, id viverra lectus fringilla iaculis.
          Nulla dolor dui, ultrices et leo ac, suscipit tempus eros. Mauris
          malesuada mattis fringilla. Fusce viverra vulputate leo, eget mollis
          est sollicitudin non. Vestibulum posuere id neque sed efficitur. Duis
          vitae mi non ipsum pellentesque condimentum ac vitae nibh. Morbi
          aliquam odio ac dui varius iaculis. Class aptent taciti sociosqu ad
          litora torquent per conubia nostra, per inceptos himenaeos. Donec
          pretium leo et nulla malesuada ultrices. Praesent consectetur neque eu
          pharetra luctus. Pellentesque porta nulla id nibh tincidunt
          ullamcorper. Cras non fermentum velit. In leo ex, sollicitudin
          convallis sodales non, dapibus id nibh. Fusce nisl massa, porta ut
          dignissim non, tempor sit amet erat.
        </Atom.Paragraph>
        <Atom.Paragraph>
          Aenean scelerisque neque augue, id viverra lectus fringilla iaculis.
          Nulla dolor dui, ultrices et leo ac, suscipit tempus eros. Mauris
          malesuada mattis fringilla. Fusce viverra vulputate leo, eget mollis
          est sollicitudin non. Vestibulum posuere id neque sed efficitur. Duis
          vitae mi non ipsum pellentesque condimentum ac vitae nibh. Morbi
          aliquam odio ac dui varius iaculis. Class aptent taciti sociosqu ad
          litora torquent per conubia nostra, per inceptos himenaeos. Donec
          pretium leo et nulla malesuada ultrices. Praesent consectetur neque eu
          pharetra luctus. Pellentesque porta nulla id nibh tincidunt
          ullamcorper. Cras non fermentum velit. In leo ex, sollicitudin
          convallis sodales non, dapibus id nibh. Fusce nisl massa, porta ut
          dignissim non, tempor sit amet erat.
        </Atom.Paragraph>
      </Atom.Container>
    </Atom.Box>
  );
};

const ConsenQuestList = props => {
  return (
    <>
      <Atom.Box>
        <Atom.Flex sx={{flexWrap: 'wrap'}}>
          {questListConsenQuest.map(quest => (
            <QuestCard {...quest} sx={stylesQuestCard} />
          ))}
        </Atom.Flex>
      </Atom.Box>
    </>
  );
};

const stylesQuestCard = {
  mx: ['0', '0', '1.5%', '1.5%'],
  mb: 4,
  width: ['100%', '100%', '47%', '30%'],
};

const SearchEngineOptimization = props => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>How It Works - EthQuest</title>
      <meta property="og:url" content="http://ethquest.io" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="How It Works | EthQuest " />
      <meta
        property="og:description"
        content="Complete EthQuest by Submitting Certificates"
      />
    </Helmet>
  );
};

const WaveBottom = props => {
  return (
    <Atom.Absolute sx={{bottom: -50, left: 0, right: 0, zIndex: 1000}}>
      <Atom.Image
        src={wave}
        sx={{
          transform: 'rotate(180deg)',
          height: 50,
          width: '100%',
        }}
      />
    </Atom.Absolute>
  );
};

export default PageHome;
